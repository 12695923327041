.calender{
    padding: 10px;
    border-radius: 5px;
    border:1px solid #D2D2D2;
    background-color: white;
    margin: 10;
}

.react-daterange-picker__button {
    margin-top: -4;
}



.headnavbaranalytics {
    box-sizing: border-box;
    position: fixed;
  }

.calender div{
    border: none;
}

.leftbutton {
    width: 20px;
    height: 20px;
}

.rightbutton {
    width: 20px;
    height: 20px;
}

.scrollbar
{
	float: left;
	background: #F5F5F5;
	/* overflow-y: scroll; */
    table-layout: "fixed";
    border-collapse: collapse;
}

.scrollbar tbody {
    overflow-y: scroll;
}


.scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(194, 194, 194, 0.3);
	border-radius: 5px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(194, 194, 194, 0.3);
	background-color: rgb(224, 219, 219);
}

.tableerrordata::-webkit-scrollbar {
    /* TODO: changed this to width 0 from display:none, so can show sidebar where needed. */
    width: 0;
}
  
.tableerrordata::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.87);
}
  
.tableerrordata::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #bdbdbd;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.87);
}

.tableerrordata::-webkit-scrollbar:horizontal {
    display: none;
}

::-webkit-scrollbar:vertical {
    display: block;
}
  
.boxcontainer {
    box-sizing: border-box;
    position: absolute;
  }
